.ViewSliderBg {
    background: url("../../semantic-ui/assets/images/reviewBg.jpg") no-repeat;
    height: 512px;
    width: 100%;
    background-size: cover;
}

.awssld {
    --slider-height-percentage: 12% !important;
    --content-background-color: transparent !important;
}

.awssld__bullets {
    bottom: 120px;
    display: none;
}

.sliderContent {
    max-width: 500px;
    padding: 20px;
    color: #ffffff;
    font-size: 14px;
}

@media (max-width: 991px) {
    .awssld {
        --slider-height-percentage: 40% !important;
    }
}
@media (max-width: 767px) {
    .awssld {
        --slider-height-percentage: 55% !important;
    }
}
