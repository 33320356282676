
.headerTop {
    background: #ffffff;
    float: left;
    width: 100%;
    transition: all .5s ease .05s;
    -webkit-transition: all .5s ease .05s;
}

.ui.menu .item {
    padding: 0.92857143em 0px !important;
    cursor: pointer;
}

.headerTop.fix {
    position: fixed;
    left: 0;
    right: 0;
    background: #ffffff;
    box-shadow: 0 0 20px -1px #424242;
    z-index: 999;
    margin: 0 !important;
    transition: all .5s ease .05s;
    -webkit-transition: all .5s ease .05s;
}

.headerTop.fix .logo {
    left: 0;
    top: 2px;
    transition: all .5s ease .05s;
    -webkit-transition: all .5s ease .05s;
}

.headerTop .ui.menu {
    background: transparent;
    box-shadow: none;
    border: 0;
}

.headerTop .ui.menu .item:before {
    width: 0;
}

.headerTop .ui.menu .item h3 {
    font-size: 24px;
    color: #ffffff;
    font-weight: normal;
}

.headerTop .ui.menu .item a {
    color: #000000 !important;
    padding: 15px 22px 15px !important;
    text-decoration: none;
}
.headerTop .ui.menu.mobile {
    background: rgba(5, 94, 124, 1);
    width: 100%;
    padding: 10px;
    position: absolute;
    right: 0;
    z-index: 99;
    top: 10%;
    border-radius: 0;
}
@media (max-width: 991px) {
    .headerTop .ui.menu .item a {
        color: #ffffff !important;
    }

    .headerTop.fix .ui.menu.mobile {
        top: 100%;
    }
}

@media (max-width: 772px) {
    .headerTop .ui.menu.mobile {
        top: 10%;
    }

    .menuFooter .item a {
        padding: 0 8px !important;
        font-size: 12px !important;
    }

    .footer {
        padding: 0;
    }



}
