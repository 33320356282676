.mt60 {
    margin-top: 60px !important;
}

.ui.steps {
    border: none !important;
}

.step i {
    color: #cf231d;
}
