

html, body, #root {
    height: 100%;
    margin: 0;
}
.footer {
    background: #cf231d;
    padding: 30px 0 15px;
    float: left;
    width: 100%;
}
.wrapper {
    min-height: 100%;
    margin-bottom: -204px;
}

.footer,
.push {
    height: 190px;
}

.footerDown {
    background: #ba0707;
    float: left;
    width: 100%;
    padding: 10px;
    color: #ffffff;
    font-weight: bold;
}
.footerDown P{
    display: inline-flex !important;
}
.ui.text.menu.menuFooter {
    width: 380px;
    margin: 0 auto;
}

.menuFooter .item a {
    color: #ffffff !important;
    padding: 0 15px;
}
.termsFooter .footer, .termsFooter .push {
    height: 90px;
}

@media (max-width: 992px) {
    .menuFooter.ui.menu .item {
        padding: 0 10px 0 0;
    }

    .menuFooter {
        text-align: center;
        padding-bottom: 10px;
    }
    .footer{
        padding: 0;
    }
}

