.servicesBg {
    background: url("../../../semantic-ui/assets/images/map.jpg") no-repeat;
    width: 100%;
    float: left;
}
.services {
    box-shadow: 0px 0px 15px #ddd !important;
    margin-top: 30px !important;
    width: 100% !important;
    min-height: 450px !important;
}
.ui.card.services .content{
    padding: 15px 25px !important;
}
.ui.card.services .content h3.header {
    font-size: 18px !important;
    color: #d10b0b;
    text-transform: uppercase;
}

.services:hover {
    background: #d10b0b !important;
}


.services:hover h3, .services:hover .description {
    color: #ffffff !important;
}
