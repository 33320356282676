/*======================WHY CHOOSE US========================*/
.feature_area {
    position: relative;
}

.features_bg:before {
    content: "";
    height: 100%;
    width: 1000%;
    background: #2b2a29;
    position: absolute;
    right: -134px;
    z-index: -1;
}

.feature_wrapper.section_padding {
    padding-bottom: 90px;
    padding-top: 90px;
    padding-left: 60px;
}

.dark_bg p {
    color: #ffffff !important;
}

.single_feature {
    margin-bottom: 12px;
}

.single_feature .feature_icon, .single_feature .feature_content {
    display: inline-block;
    position: relative;
}

.single_feature .feature_icon i {
    font-size: 30px;
    line-height: 60px;
    width: 60px;
    text-align: center;
    border: 1px solid #ffffff;
    color: #ffffff;
    height: 60px;
}

.single_feature .feature_icon:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 20px;
    background: #ffffff;
    top: 28%;
    left: 94%;
}

.single_feature .feature_content {
    width: calc(100% - 65px);
    padding-left: 18px;
}

.single_feature .feature_title {
    margin-bottom: 5px;
}

.feature_title h4 {
    color: #ffffff;
    font-size: 18px !important;
    line-height: 28px;
    text-transform: capitalize;
}

.single_feature .feature_icon {
    vertical-align: top;
    padding-top: 7px;
}

.chooseUs {
    width: 50%;
    height: 700px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background: url("../../semantic-ui/assets/images/truckBig.jpg");
}

@media (max-width: 772px) {
    .features_bg:before {
        width: 100%;
        right: 0;
    }
}

/*======================WHY CHOOSE US End========================*/
